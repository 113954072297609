.cardAction {
    border-top: 1px solid rgba(160, 160, 160, 0.2);
}


.card {
    font-size: 1.5em;
    margin-top: 10px;
    width: 30%;
    height: 48%;
    boxShadow: "11px 7px 5px 2px black";
    border: 2px solid rgba(160,160,160,0.2);
    /*transition: transform 0.15s ease-in-out;*/
    transition: .75s;
    margin-left: 10px;
    background: white;
    border-radius: 5px;
    float: left;
}

@media (max-width: 768px) { /* Mobile app display */
    #question {
        font-size: 1.2em;
    }
    .card {
        width: 100%;
        height: 45vh;
    }
}

.card :hover {
    /*transform: scale3d(1.02, 1.02, 1)*/
}
.card-media {
    height: 45%;
    border-bottom: 6px solid #2155a8;
}

.card-content {
    border-top: 2px solid rgba(160,160,160,0.2);
    padding: 3px;
}

.card-bottom {
    height: 10%;
    borderTop: 2px solid rgba(160,160,160,0.2);
    marginLeft: "5%";
}

.project-details {
    font-size: 1.3em;
}
.ribbon {
    position: absolute;
    top: 10px;
    right: -40px;
    width: 150px;
    background: #ff6347; /* Tomato color */
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    transform: rotate(45deg);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 5px 0;
    /*inset: 0 0 auto auto;*/
    /*background: green;*/
    /*transform: translate(30%) rotate(45deg);*/
}