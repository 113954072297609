.App {
    text-align: center;
    background-color: black;
    height: 90%;
}

body,
html {
    width: 100%;
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #c21313;
}

.App-link {
    color: #61dafb;
}

.background {
    /*background-image: url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a6a0b014-7dd0-40ab-8337-15e1bda499c2/dfty7zv-a36b7c69-3bb1-429b-8d60-0e8db2b949d5.png/v1/fill/w_1280,h_718,q_80,strp/dream_library_by_soraxtm_dfty7zv-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzE4IiwicGF0aCI6IlwvZlwvYTZhMGIwMTQtN2RkMC00MGFiLTgzMzctMTVlMWJkYTQ5OWMyXC9kZnR5N3p2LWEzNmI3YzY5LTNiYjEtNDI5Yi04ZDYwLTBlOGRiMmI5NDlkNS5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.7EsxvH3Cj2odAYUtlauXgdCSWqrV_rPLz8X__6bbPXE'); */
    /*background-image: url('https://www.observatoiredeleurope.com/wp-content/uploads/2024/04/1712595277_The-Matrix-25-ans-apres-une-nouvelle-conspiration-virale.jpg');*/
    background-image: url('https://wallpapers.com/images/featured/grey-5xvepms9fbbi0bmh.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 100%;
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: center;
    align-items: center;
}
#question {
    color: rgb(240, 250, 250);
    font-family: "Georgia", "Times", "serif"; /* Warm, classic fonts */
    font-size: 5rem;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 2px 2px #161a16;
}
/*@keyframes App-logo-spin {*/
/*    from {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    to {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/
