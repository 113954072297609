#projects-container {
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    /*background-color: #919196;*/
    height: 100%;


}

h1 {
    margin-top: 8px;
    margin-bottom: 3px;
}